import {Dispatch, SetStateAction, useEffect} from 'react';
import TextField from '@components/Forms/TextField';
import FormGroup from '@components/Forms/FormGroup';

import Button from '@components/Forms/Button';
import {useModal} from '@contexts/index';
import {ModalActionTypes} from '@contexts/actions';
import {ModalHeader, ModalBody, Modal, Label} from 'reactstrap';
import {useForm} from 'react-hook-form';
import {useSession} from 'next-auth/react';
import {useRouter} from 'next/router';
import {toast} from 'react-toastify';
import {useTranslation} from 'next-i18next';
import {Spinner} from 'reactstrap';
import styles from './style.module.css';
import {PRICE_LIST_CHECK_LIST} from '@constants/common';
import {useRequest} from '@hooks/use-request';
import {LocaleEnum} from '@enums/locale';

interface IPriceListModalProps {
  openModal: boolean;
  setOpenModal: Dispatch<SetStateAction<boolean>>;
}

export default function PriceListModal({
  openModal,
  setOpenModal,
}: IPriceListModalProps) {
  const router = useRouter();

  const locale = router.locale as LocaleEnum;
  const {sendRequest, loading} = useRequest();
  const {t} = useTranslation('common');
  const {status, data: session} = useSession();
  const {dispatch} = useModal();
  const {
    register,
    handleSubmit,
    setValue,
    formState: {errors},
  } = useForm();

  useEffect(() => {
    setValue('name', session?.user.name);
    setValue('email', session?.user.email);
  }, [session]);

  const handleFormSubmit = async (data: any) => {
    if (!Array.isArray(data.group_ids)) {
      data.group_ids = [data.group_ids];
    }
    const message = {
      fi: {
        success: 'Hinnasto on lähetetty sähköpostiisi',
        error: 'Tapahtui virhe',
      },
      en: {
        success: 'Price list has been sent to your email',
        error: 'An error occurred',
      },
      se: {
        success: 'Price list has been sent to your email',
        error: 'An error occurred',
      },
    };

    await sendRequest({
      url: 'send-price-list',
      method: 'post',
      requiresAuth: true,
      body: data,
      onSuccess: () => {
        toast.success(message[locale].success);
        setOpenModal(false);
      },
      onError: e => {
        toast.error(message[locale].error);
      },
    });
  };

  const showLoginModal = () =>
    dispatch({type: ModalActionTypes.TOGGLE_LOGIN_MODAL});

  return (
    <Modal isOpen={openModal} center="true" animation="false" size="lg">
      <ModalHeader
        toggle={() => setOpenModal(false)}
        className={styles.modalHeader}
      >
        <div className={styles.loginHeader}>
          {t<string>('price_list_modal.title')}
        </div>
      </ModalHeader>
      <ModalBody className={styles.loginModalBody}>
        <div className="row">
          <div className="col-12 col-md-6">
            <h5 className="text-primary">
              {t<string>('price_list_modal.heading')}
            </h5>
            <p>{t<string>('price_list_modal.description')}</p>
            <p className="h5 text-primary">
              {t<string>('price_list_modal.small_descr')}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <h5 className="text-primary">
                {t<string>('price_list_modal.heading_2')}:
              </h5>

              {PRICE_LIST_CHECK_LIST.map(item => (
                <FormGroup key={item.id}>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={item.id}
                    {...register('group_ids')}
                  />{' '}
                  <Label check>{item.label}</Label>
                </FormGroup>
              ))}

              <div className="mb-5">
                {status === 'unauthenticated' && (
                  <Button
                    onClick={() => showLoginModal()}
                    type={'button'}
                    classNames={'mb-3'}
                    block={true}
                  >
                    {t<string>('login.login_button')}{' '}
                  </Button>
                )}
                <FormGroup>
                  <input
                    id="only-sale-item"
                    className="form-check-input"
                    type="checkbox"
                    {...register('only_sale_items')}
                  />{' '}
                  <Label htmlFor="only-sale-item" check>
                    &nbsp;{t<string>('price_list_modal.show_only')}
                  </Label>
                </FormGroup>

                {status !== 'authenticated' && (
                  <div>
                    <FormGroup>
                      <TextField
                        required
                        type="email"
                        error={errors?.email?.message}
                        {...register('email')}
                        placeholder={t<string>('price_list_modal.email')}
                      />
                    </FormGroup>

                    <FormGroup>
                      <TextField
                        required
                        type="text"
                        error={errors?.name?.message}
                        {...register('name')}
                        placeholder={t<string>('price_list_modal.name')}
                      />
                    </FormGroup>
                  </div>
                )}

                <Button disabled={loading} type={'submit'} block={true}>
                  {t<string>('price_list_modal.send_list')}{' '}
                  {loading && <Spinner className={'ms-2'} size={'sm'} />}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
