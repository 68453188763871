import styles from './style.module.css';
import {ReactNode} from 'react';

interface FormGroupProps {
  classNames?: string;
  mandatory?: boolean;
  children?: ReactNode;
}
export default function FormGroup({
  children,
  classNames = '',
  mandatory,
}: FormGroupProps) {
  return (
    <div
      className={`${classNames} ${styles.formGroup} ${
        mandatory && styles.mandatory
      }`}
    >
      {children}
    </div>
  );
}
