import Image from 'next/image';

import RadioButton from '@components/Forms/RadioButton';

import styles from './style.module.css';
import {toThousand} from '@lib/utils';
import {MouseEventHandler} from 'react';

export default function ProductAddonItem(props: {
  selectable?: boolean;
  product_name: string;
  product_id: number;
  price: number;
  product_image: string;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLLIElement>;
}) {
  const {
    selectable = true,
    product_name,
    price,
    product_image,
    isSelected,
    onClick,
  } = props;

  return (
    <li onClick={onClick} className={styles.productAddonItem}>
      <div className={`${styles.productAddonItemImg}`}>
        {product_image ? (
          <Image
            height={50}
            alt={product_name}
            title={product_name}
            width={50}
            src={product_image}
          />
        ) : null}
      </div>
      <div className={`${styles.productAddonItemInfo}`}>
        <h6>{product_name}</h6>
        <h6 className={`${styles.productAddonItemPrice}`}>
          +{toThousand(price)} €
        </h6>
      </div>
      {selectable ? (
        <RadioButton classNames={styles.radioSelector} isChecked={isSelected} />
      ) : null}
    </li>
  );
}
