import styles from './style.module.css';
import Button from '@components/Forms/Button';
import {Input, InputGroup} from 'reactstrap';
import {useState} from 'react';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';

export default function SearchDropDown({visible}: {visible: boolean}) {
  const router = useRouter();
  const [search, setSearch] = useState('');
  const {t} = useTranslation('common');
  return (
    <>
      <div className={`${visible && styles.visible} ${styles.searchDropdown}`}>
        <div>
          <InputGroup>
            <Input
              defaultValue={search}
              onChange={e => setSearch(e.target.value)}
              onKeyUp={e => {
                if (e.key === 'Enter') router.push(`/hae?q=${search}`);
              }}
            />
            <Button onClick={() => router.push(`/hae?q=${search}`)}>
              {t<string>('search_button')}
            </Button>
          </InputGroup>
        </div>
      </div>
    </>
  );
}
