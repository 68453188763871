import {
  ChangeEventHandler,
  FocusEventHandler,
  ForwardedRef,
  forwardRef,
  ReactNode,
} from 'react';
import {Input} from 'reactstrap';
import styles from './style.module.css';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface TextFieldProps extends ComponentWithChildrenProps {
  type?: 'text' | 'password' | 'select' | 'email' | 'number';
  value?: any;
  error?: any | string;
  defaultValue?: any;
  onChange?: ChangeEventHandler<HTMLInputElement | HTMLSelectElement>;
  onBlur?: FocusEventHandler<HTMLInputElement | HTMLSelectElement>;
  name?: string;
  icon?: ReactNode;
  size?: 'md' | 'sm';
  placeholder?: string;
  classNames?: string;
  required?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  id?: string;
  children?: ReactNode;
  step?: number;
}
const TextField = forwardRef(
  (props: TextFieldProps, ref: ForwardedRef<any>) => {
    const {
      type = 'text',
      onChange,
      name,
      onBlur,
      error = null,
      icon,
      size = 'md',
      placeholder = '',
      children,
      classNames = '',
      ...otherProps
    } = props;
    return type === 'select' ? (
      <>
        <Input
          type={'select'}
          className={`${styles.textField} ${styles.selectField} `}
          onChange={onChange}
          innerRef={ref}
          name={name}
          onBlur={onBlur}
          {...otherProps}
        >
          {children}
        </Input>
        {error && <small className="mt-1 text-danger">{error}</small>}
      </>
    ) : (
      <>
        <div
          className={`${styles.textField} 
         ${size === 'sm' ? styles.smallTextField : ''}
         ${classNames}`}
        >
          <input
            ref={ref}
            name={name}
            onBlur={onBlur}
            onChange={onChange}
            autoComplete={'off'}
            type={type}
            placeholder={placeholder}
            {...otherProps}
          />
          {icon && (
            <button tabIndex={-1} type={'button'}>
              {icon}
            </button>
          )}
        </div>
        {error && <small className="mt-1 text-danger">{error}</small>}
      </>
    );
  }
);

TextField.displayName = 'TextField';
export default TextField;
