import TextField from '@components/Forms/TextField';
import {
  faEye,
  faEyeSlash,
  faExclamationCircle,
  faKey,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormGroup from '@components/Forms/FormGroup';

import Button from '@components/Forms/Button';

import {useModal} from '@contexts/index';
import {ModalActionTypes as Actions} from '@contexts/actions';
import {ModalHeader, ModalBody, Modal} from 'reactstrap';

import {FormEvent, useState} from 'react';
import {signIn} from 'next-auth/react';
import Link from '@components/Common/Link';
import {useRouter} from 'next/router';
import {toast} from 'react-toastify';
import {useTranslation} from 'next-i18next';
import {Spinner} from 'reactstrap';

import styles from './style.module.css';

export default function LoginModal() {
  const {t} = useTranslation(['common']);
  const {state, dispatch} = useModal();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const {isLoginModalOpen} = state;
  const router = useRouter();

  const toggleModal = () => {
    dispatch({type: Actions.TOGGLE_LOGIN_MODAL});
  };

  const redirectToProfilePage = (routerPath: string) => {
    return !['/tuotteet/tuote-naytto/[slug]'].find(path => routerPath === path);
  };

  const handleLoginForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(false);
    setLoading(true);

    const result = await signIn('customer-login', {
      redirect: false,
      email: email,
      password: password,
    });
    if (!result) {
      return;
    }
    const {error} = result;
    if (error === null) {
      setEmail('');
      setPassword('');
      toast.success(t<string>('login.success'));
      toggleModal();
      if (redirectToProfilePage(router.pathname)) {
        await router.push(t('login.redirect_url'));
      } else {
        setTimeout(() => {
          router.reload();
        }, 1500);
      }
    } else {
      setError(true);
    }
    setLoading(false);
  };
  return (
    <Modal
      key={111}
      contentClasses={styles.loginModalContent}
      isOpen={isLoginModalOpen}
      center={true}
    >
      <ModalHeader toggle={toggleModal} className={styles.modalHeader}>
        <div className={styles.loginHeader}>
          <div className={styles.modalHeaderIcon}>
            <FontAwesomeIcon icon={faKey} />
          </div>
          <div className={styles.modelHeaderTitle}>
            <h4> {t<string>('login.title')},</h4>
            <p> {t<string>('login.sub_title')}</p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody classNames={styles.loginModalBody}>
        <div className="login-body">
          <form onSubmit={handleLoginForm}>
            <FormGroup>
              <label htmlFor="">{t<string>('form_fields.email_address')}</label>
              <TextField
                onChange={e => setEmail(e.target.value)}
                required
                value={email}
                placeholder={t('form_fields.email_address')}
                type={'email'}
              />
              {error && (
                <p
                  style={{fontSize: '14px'}}
                  className={'mb-0 text text-danger'}
                >
                  {t<string>('login.error_message')}
                </p>
              )}
            </FormGroup>

            <FormGroup>
              <label htmlFor="">{t<string>('form_fields.password')}</label>
              <TextField
                onChange={e => setPassword(e.target.value)}
                value={password}
                placeholder={t('form_fields.password')}
                required
                type={passwordVisible ? 'text' : 'password'}
                icon={
                  <FontAwesomeIcon
                    onClick={() => setPasswordVisible(!passwordVisible)}
                    icon={passwordVisible ? faEye : faEyeSlash}
                  />
                }
              />
            </FormGroup>
            <FormGroup classNames={'d-flex  justify-content-between'}>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                />
                <label className="form-check-label" htmlFor="flexCheckDefault">
                  {t<string>('login.remember_me')}
                </label>
              </div>
              <p className={styles.forgotPassword}>
                <Link
                  {...{tabIndex: -1}}
                  variant={'buttonLink'}
                  onClick={() =>
                    dispatch({type: Actions.TOGGLE_FORGOT_PASSWORD_MODAL})
                  }
                  classNames={'text-danger'}
                >
                  {t<string>('login.forgot_password')}?
                </Link>
              </p>
            </FormGroup>
            <FormGroup>
              <Button
                disabled={!email || !password || loading}
                type={'submit'}
                block={true}
                {...{tabIndex: 11}}
              >
                {t<string>('login.login_button')}
                {loading && <Spinner size={'sm'} className={'ms-2'} />}
              </Button>
            </FormGroup>
          </form>
          <div className={styles.DisclaimerContainer}>
            <h4 className={styles.DisclaimerTitle}>
              <FontAwesomeIcon icon={faExclamationCircle} />
              {t<string>('login.note')}!
            </h4>
            <p>{t<string>('login.note_paragraph_one')}</p>
            <p>{t<string>('login.note_paragraph_two')}</p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}
