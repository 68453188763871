import NavLink from '@components/Common/NavLink';

import Image from 'next/image';
import Logo from '@images/logo.png';

import {
  faSearch,
  faBars,
  faUser,
  faShoppingCart,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {useRouter} from 'next/router';
import Link from '@components/Common/Link';
import {useCart, useModal} from '@contexts/index';

import {useEffect, useState} from 'react';
import CartDropdown from '@components/Header/CartDropDown';
import NavItem from '@components/Common/NavItem';
import {HeaderNavigation, MyAccountLink} from '@constants/navigations';
import SearchDropDown from './SearchDropDown';
import {LocaleEnum} from '@enums/locale';

import styles from './MainNav.module.css';

export default function MainNav({
  toggleSideMenu,
  sessionStatus,
}: {
  toggleSideMenu: () => void;
  sessionStatus: 'authenticated' | 'unauthenticated' | 'loading';
}) {
  const router = useRouter();
  const locale = router.locale as unknown as LocaleEnum;
  const links = HeaderNavigation[locale];

  const {
    state: {itemsCount},
    fetchUserCartItems,
    getPaymentAndDeliveryMethods,
  } = useCart();

  const [isCartOpen, setIsCartOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    fetchUserCartItems();
    getPaymentAndDeliveryMethods();
  }, []);

  return (
    <div className={styles.mainNavContainer}>
      <div className="container-md">
        <div className="row">
          <div className="col-12 d-flex align-items-center">
            <div className="logo_container">
              <Link href="/" locale={locale}>
                <Image height={50} width={123} src={Logo} alt="Liune logo" />
              </Link>
            </div>
            <nav className={`navbar ${styles.mainNavBar}`}>
              <ul className={`d-none d-lg-block ${styles.mainNavBarMenu}`}>
                {links.map((link, index) => {
                  return (
                    <NavLink key={index} href={link.link} locale={locale}>
                      {link.name}
                    </NavLink>
                  );
                })}
              </ul>
              <ul className={styles.mainNavBarUser}>
                <NavItem>
                  <Link
                    onClick={() => setIsSearchOpen(!isSearchOpen)}
                    variant={'buttonLink'}
                  >
                    <FontAwesomeIcon icon={faSearch} />
                  </Link>
                  <SearchDropDown visible={isSearchOpen} />
                </NavItem>
                {sessionStatus === 'authenticated' && (
                  <NavItem>
                    <Link href={`${MyAccountLink[locale].link}`}>
                      <FontAwesomeIcon icon={faUser} />
                    </Link>
                    <SearchDropDown visible={isSearchOpen} />
                  </NavItem>
                )}

                <NavItem classNames={`${styles.mainNavBarUserCheckout}`}>
                  <Link
                    onClick={() => setIsCartOpen(!isCartOpen)}
                    variant={'buttonLink'}
                  >
                    <FontAwesomeIcon icon={faShoppingCart} />
                    <span className={`${styles.checkoutItems}`}>
                      {itemsCount}
                    </span>
                  </Link>
                  <CartDropdown visible={isCartOpen} />
                  <div
                    onClick={() => setIsCartOpen(false)}
                    className={`${styles.cartItemBackdrop}`}
                    style={{display: isCartOpen ? 'block' : 'none'}}
                  ></div>
                </NavItem>
              </ul>
              <div
                onClick={toggleSideMenu}
                className={`${styles.hamburgerContainer}`}
              >
                <FontAwesomeIcon icon={faBars} />
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
