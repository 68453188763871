import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircle} from '@fortawesome/free-solid-svg-icons';
import {faCircle as faAltCircle} from '@fortawesome/free-regular-svg-icons';
import styles from './style.module.css';
import {MouseEventHandler} from 'react';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface RadioButtonProps extends ComponentWithChildrenProps {
  isChecked?: boolean;
  classNames?: string;
  onClick?: MouseEventHandler<HTMLSpanElement>;
}
export default function RadioButton(props: RadioButtonProps) {
  const {isChecked, onClick, classNames = '', ...rest} = props;
  return (
    <span
      onClick={onClick}
      className={`${styles.radioRoot}  ${isChecked ? styles.radioChecked : ''}
               ${classNames}`}
    >
      <input readOnly {...rest} type="radio" checked={isChecked} />
      <span className={styles.radioIcons}>
        <FontAwesomeIcon className={styles.radioIcon} icon={faAltCircle} />
        <FontAwesomeIcon
          icon={faCircle}
          className={`${styles.radioIcon}
             ${styles.radioIconInner}`}
        />
      </span>
    </span>
  );
}
