import Image from 'next/image';

import {sumPrice, toThousand} from '@lib/utils';
import {useRouter} from 'next/router';
import Link from '@components/Common/Link';
import {useTranslation} from 'next-i18next';
import {OrderRowInterface} from '@interfaces/order';
import {LocaleEnum} from '@enums/locale';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleDown, faTrash} from '@fortawesome/free-solid-svg-icons';
import DropDown from '@components/Common/DropDown';
import DropDownToggle from '@components/Common/DropDown/DropDownToggle';
import DropDownMenu from '@components/Common/DropDown/DropDownMenu';
import ProductAddonItem from '@components/ProductAddons/ProductAddonItem';
import {useState} from 'react';
import styles from './style.module.css';
export default function CartDropdownItem({
  item,
  deleteCartItem,
}: {
  item: OrderRowInterface & {linked_products: OrderRowInterface[]};
  deleteCartItem: (orderRowUUID: string) => void;
}) {
  const [toggleAccessories, setToggleAccessories] = useState(false);
  const {t} = useTranslation('common');
  const router = useRouter();
  const locale = router.locale as LocaleEnum;
  const {
    uuid,
    u_qty,
    product,
    images,
    quantity,
    discount_unit_price_vat_excluded,
    unit_price_vat_excluded,
    linked_products,
    unit_weight,
  } = item;
  const accessories = linked_products.filter(
    item => item.unit_price_vat_excluded > 0
  );
  const product_name = product[`product_name_${locale}`];
  const image = images[0]?.image_fi;
  const totalQuantity = u_qty ?? quantity;
  const totalPrice =
    (unit_price_vat_excluded +
      sumPrice(accessories, 'unit_price_vat_excluded')) *
    totalQuantity;
  const discountTotalPrice =
    (discount_unit_price_vat_excluded +
      sumPrice(accessories, 'discount_unit_price_vat_excluded')) *
    totalQuantity;
  return (
    <div className={`${styles.cartItem}`}>
      <div>
        <div className={`${styles.cartDropdownImage}`}>
          {image && (
            <Image alt={product_name} src={`${image}`} height={65} width={65} />
          )}
        </div>
        <div className={`${styles.productInformation}`}>
          <h6>{product_name}</h6>
          <p>{unit_weight > 0 && `${unit_weight} kg`}</p>
          {accessories.length > 0 && (
            <DropDown>
              <DropDownToggle
                isOpen={toggleAccessories}
                toggle={() => {
                  setToggleAccessories(!toggleAccessories);
                }}
                classNames={`${styles.cartItemToggleButton} 
                               ${
                                 toggleAccessories ? styles.buttonToggled : ''
                               }`}
              >
                <span>
                  {t<string>('header.cart_dropdown.accessories')} (+
                  {accessories.length})
                </span>
                <FontAwesomeIcon icon={faAngleDown} />
              </DropDownToggle>
              <DropDownMenu
                style={{display: toggleAccessories ? 'block' : 'none'}}
                isOpen={toggleAccessories}
                classNames={styles.cartItemDropDownMenu}
              >
                {accessories.map(item => {
                  const {images, product, discount_unit_price_vat_excluded} =
                    item;
                  return (
                    <ProductAddonItem
                      key={item.id}
                      selectable={false}
                      product_name={product[`product_name_${locale}`]}
                      product_id={item.id}
                      price={discount_unit_price_vat_excluded}
                      product_image={images[0]?.image_fi}
                    />
                  );
                })}
              </DropDownMenu>
            </DropDown>
          )}
          <p className={`${styles.quantity}`}>
            {t<string>('dropdown_cart.qty')}: {u_qty || quantity || 1}
          </p>
          <p>
            <Link
              variant={'buttonLink'}
              classNames={'text-danger'}
              onClick={e => {
                e.stopPropagation();
                deleteCartItem(uuid);
              }}
            >
              <FontAwesomeIcon icon={faTrash} className={'me-1'} />
            </Link>
          </p>
        </div>
        <div className={styles.itemTotal}>
          <h6> {toThousand(discountTotalPrice)}€</h6>
          {totalPrice > discountTotalPrice && (
            <h6 className={styles.totalPrice}> {toThousand(totalPrice)}€</h6>
          )}
        </div>
      </div>
    </div>
  );
}
