import Header from '@components/Header';
import Footer from '@components/Footer';
import LoginModal from '@components/Auth/LoginModal';
import ForgotPasswordModal from '@components/Auth/ForgotPasswordModal';

import styles from './style.module.css';
import Head from 'next/head';
import {ComponentWithChildrenProps} from '@interfaces/component';
import {EEnviroment} from '@enums/config';

export default function MainLayout({children}: ComponentWithChildrenProps) {
  return (
    <div className={'main-layout'}>
      <Head>
        <meta charSet="utf-8" />
        <meta name="author" content="Liune" key={'author'} />
        <link
          as="font"
          crossOrigin="anonymous"
          href="https://assets.vercel.com/raw/upload/v1660068731/fonts/4/Inter.var.latin.woff2"
          rel="preload"
          type="font/woff2"
        />
        <link rel="icon" href="/favicon.ico" />
        <meta name="copyright" content="Liune" />
        {process.env.NEXT_PUBLIC_APP_ENV === EEnviroment.PROD ? (
          <meta name="robots" content="index, all" />
        ) : (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <meta name="revisit-after" content="2 days" />
        <meta name="document-class" content="Published" />
        <meta name="document-classification" content="Corporate - Site" />
        <meta name="document-distribution" content="Global" />
        <meta name="cache-control" content="Public" />
        <meta name="Publisher" content="HD SofT & OnsaWeb" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Header />
      <LoginModal />
      <ForgotPasswordModal />
      <main className={styles.main}>{children}</main>
      <Footer />
    </div>
  );
}
