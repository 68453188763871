export const TopHeaderNavigation = {
  fi: [
    {
      name: 'KOTIMAINEN',
      link: '/naytasivu/kotimaisuus-ja-design',
    },
    {
      name: 'NOPEA TOIMITUS',
      link: '/naytasivu/toimitusnopeus-ja-kotimaisuus-takaavat-onnistuneen-lopputuloksen',
    },
    {
      name: 'AJANKOHTAISTA',
      link: '/naytasivu/ajankohtaista',
    },
  ],
  en: [
    {
      name: 'DOMESTIC',
      link: '/en/showpage/nordic-design',
    },
    {
      name: 'FAST DELIVERY',
      link: '/en/showpage/reliable-finnish-production-and-delivery',
    },
  ],
  se: [
    {
      name: 'NORDISK PRODUKTION & DESIGN',
      link: '/se/visasida/nordisk-design',
    },
    {
      name: 'SNABB LEVERANS',
      link: '/se/visasida/palitlig-finlandsk-produktion-och-leverans',
    },
  ],
};
export const HeaderNavigation = {
  fi: [
    {
      name: 'Tuotteet',
      link: '/tuotteet/liune-door',
    },
    {
      name: 'Ratkaisut ja Palvelut',
      link: '/naytasivu/liunen-ratkaisut-ja-palvelut',
    },
    {
      name: 'Aineistot ja materiaalit',
      link: '/naytasivu/aineistot-ja-materiaalit',
    },
    {
      name: 'Yhteystiedot',
      link: '/naytasivu/liune-yhteystiedot',
    },
  ],
  en: [
    {
      name: 'Products',
      link: '/en/products/liune-sliding-door',
    },
    {
      name: 'Solutions and Services',
      link: '/en/showpage/solutions-and-services',
    },
    {
      name: 'Materials',
      link: '/en/showpage/materials',
    },
    {
      name: 'Contact',
      link: '/en/showpage/liune-contacts',
    },
  ],
  se: [
    {
      name: 'Produkter',
      link: '/se/produkter/liune-door',
    },
    {
      name: 'Lösningar och tjänster',
      link: '/se/visasida/liunes-losningar-och-tjanster',
    },
    {
      name: 'Filer och material',
      link: '/se/visasida/filer-och-material',
    },
    {
      name: 'Kontaktuppgifter',
      link: '/se/visasida/kontaktuppgifter',
    },
  ],
};

export const MyAccountLink = {
  fi: {name: 'Profiili', link: '/tili/profiili'},
  en: {name: 'Profile', link: '/en/account/profile'},
  se: {name: 'Profil', link: '/se/konto/profil'},
};

export const AccountDropDownLinks = {
  fi: [
    {
      name: 'Tilaukseni',
      link: '/tili/tilauksia',
    },
    {
      ...MyAccountLink.fi,
    },
  ],
  en: [
    {
      name: 'My Orders',
      link: '/en/account/orders',
    },
    {
      ...MyAccountLink.en,
    },
  ],
  se: [
    {
      name: 'Mina beställningar',
      link: '/se/konto/order',
    },
    {
      ...MyAccountLink.se,
    },
  ],
};

export const ForgotPasswordLinks = {
  en: '/en/password',
  fi: '/salasana',
  se: '/se/losenord',
};

export const ProductsLink = {
  name_en: 'Products',
  en: '/en/products/liune-sliding-door',
  name_fi: 'Tuotteet',
  fi: '/tuotteet/liune-door',
  se: '/se/produkter/liune-door',
  name_se: 'Produkter',
};

export const AskOfferLinks = {
  name_fi: 'Pyydä tarjous',
  link_fi: '/palaute',
  name_en: 'Ask for a Quote',
  link_en: '/en/feedback',
  name_se: 'Be om ett erbjudande',
  link_se: '/se/respons',
};

export const ContactInformationLinks = {
  name_fi: 'Yhteystiedot',
  link_fi: '/naytasivu/liune-yhteystiedot',
  name_en: 'Contact Information',
  link_en: '/en/showpage/liune-contacts',
  name_se: 'Kontaktuppgifter',
  link_se: '/se/visasida/kontaktuppgifter',
};

export const DownloadLoadBrochuresLink = {
  name_fi: 'Lataa esitteitä ja muuta materiaalia',
  link_fi: '/esitteet',
  name_en: 'Download brochures',
  link_en: '/en/brochures',
  name_se: 'Ladda ner broschyrer',
  link_se: '/se/broschyrer',
};

export const BuyersGuidsLink = {
  name_fi: 'Ostajan opas',
  link_fi: '/naytasivu/liune-ostajan-opas',
  name_en: 'Buyers guide',
  link_en: '/en/showpage/liune-buyers-guide',
  name_se: 'Guide för köpare',
  link_se: '/se/visasida/liune-guide-for-kopare',
};
