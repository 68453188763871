import enImg from '@images/flag_en.png';
import finImg from '@images/flag_fin.png';
import sweImg from '@images/flag_swe.png';

//Constants for the languages
export const English = 'en';
export const Finnish = 'fi';
export const Swedish = 'se';

export const EN = 'en';
export const FI = 'fi';
export const SE = 'se';

export const OrderTypes = {
  Orders: 'Orders',
  Quotations: 'Quotations',
};

export const languages = [
  {
    title: 'English',
    locale: English,
    flag: enImg,
  },
  {
    title: 'Suomi',
    locale: Finnish,
    flag: finImg,
  },
  {
    title: 'SVENSKA',
    locale: Swedish,
    flag: sweImg,
  },
];

export const CMS_BASE_URL = {
  en: {
    link: '/en/showpage/',
    as: '/en/naytasivu/',
  },
  fi: {
    link: '/naytasivu/',
    as: '/naytasivu/',
  },
  se: {
    link: '/se/visasida/',
    as: '/se/naytasivu/',
  },
};

export const REFERENCE_BASE_URL = {
  en: {
    link: '/en/showreference/',
    as: '/en/naytareferenssi/',
  },
  fi: {
    link: '/naytareferenssi/',
    as: '/naytareferenssi/',
  },
  se: {
    link: '/se/visareferens/',
    as: '/se/naytareferenssi/',
  },
};

export const PRODUCT_GROUPS_BASE_URL = {
  en: {
    link: '/en/products/',
    as: '/en/tuotteet/',
  },
  fi: {
    link: '/tuotteet/',
    as: '/tuotteet/',
  },
  se: {
    link: '/se/produkter/',
    as: '/se/tuotteet/',
  },
};

export const HASH_TAGS = ['liunedoor', 'liune', 'taskuovi'];

export const CHECKOUT_URL = {
  fi: '/ostokori/tarkista',
  en: '/cart/checkout',
  se: '/kundvagn/kolla-upp',
};

export const ORDER_DETAILS_URL = {
  fi: '/tilaukset/',
  en: '/en/orders/',
  se: '/se/order/',
};

export const PRICE_LIST_CHECK_LIST = [{id: 6, label: 'LIUNE DOOR'}];
