import styles from './styles.module.css';
import {ComponentWithChildrenProps} from '@interfaces/component';

export default function DropDownToggle(
  props: ComponentWithChildrenProps & {
    toggle?: () => void;
    classNames?: string;
    isOpen?: boolean;
  }
) {
  const {children, toggle, classNames, isOpen} = props;
  return (
    <button
      onClick={toggle}
      onBlur={() => {
        if (isOpen && toggle) {
          toggle();
        }
      }}
      className={`${styles.dropDownToggle} ${classNames}`}
      type={'button'}
    >
      {children}
    </button>
  );
}
