import NavItem from '@components/Common/NavItem';

import {useState} from 'react';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface SelectionProps extends ComponentWithChildrenProps {
  classNames?: string;
  activeClass?: string;
}
export default function Selection({
  children,
  classNames = '',
  activeClass = '',
}: SelectionProps) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <NavItem
      onClick={toggle}
      classNames={`${classNames} ${isOpen ? `${activeClass}` : ''}`}
    >
      {children}
    </NavItem>
  );
}
