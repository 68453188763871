import React, {ReactNode, useContext} from 'react';

import {
  ModalContext,
  ProductDisplayContext,
  UserCartContext,
} from '@contexts/providers';

export interface ContextProps {
  children: ReactNode;
}

export function useModal() {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error('useModal must be used within Modal Context Provider');
  }
  return context;
}

export function useProductDisplay() {
  const context = useContext(ProductDisplayContext);
  if (context === undefined) {
    throw new Error(
      'useProductDisplay must be used within Product Display Context Provider'
    );
  }
  return context;
}

export function useCart() {
  const context = useContext(UserCartContext);
  if (context === undefined) {
    throw new Error('useCart must be used within User Cart  Context Provider');
  }
  return context;
}
