import styles from './styles.module.css';
import {ComponentWithChildrenProps} from '@interfaces/component';

export default function DropDownMenu(
  props: ComponentWithChildrenProps & {
    isOpen?: boolean;
    classNames?: string;
    [key: string]: unknown;
  }
) {
  const {children, isOpen, classNames = ''} = props;
  return (
    <div
      className={`${styles.dropDownMenu} ${
        isOpen === true ? styles.isOpen : ''
      } ${classNames}`}
    >
      {children}
    </div>
  );
}
