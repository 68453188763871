import TopNav from './TopNav';
import MainNav from './MainNav';
import HamburgerMenu from './HamburgerMenu';
import {useEffect, useState} from 'react';
import {useSession} from 'next-auth/react';
import {reloadSession} from '@lib/utils';

export default function Header() {
  const [isSideMenuOpen, setSideMenuOpen] = useState(false);
  const toggleSideMenu = () => setSideMenuOpen(!isSideMenuOpen);
  const {status} = useSession();
  useEffect(() => {
    reloadSession();
  }, []);
  return (
    <header className={'header'}>
      <TopNav sessionStatus={status} />
      <MainNav sessionStatus={status} toggleSideMenu={toggleSideMenu} />
      <HamburgerMenu toggle={toggleSideMenu} isOpen={isSideMenuOpen} />
    </header>
  );
}
