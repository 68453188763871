import styles from './style.module.css';
import CartDropdownItem from '@components/Header/CartDropDown/CartDropDownItem';
import Button from '@components/Forms/Button';
import {useRouter} from 'next/router';
import {useCart} from '@contexts/index';
import {getVATTextKey, toThousand} from '@lib/utils';
import CartDropdownLoaderList from '@content-loader/CartDropdownLoader';
import {useTranslation} from 'next-i18next';
import {LocaleEnum} from '@enums/locale';
import {CHECKOUT_URL} from '@constants/common';

export default function CartDropdown({visible}: {visible: boolean}) {
  const {t} = useTranslation('common');
  const router = useRouter();
  const locale = router.locale as LocaleEnum;
  const {
    state: {
      itemsCount,
      loading,
      cartItems: {
        rows: order_rows,
        delivery_method_price_vat_excluded,
        total_price_vat_included,
        total_price_vat_excluded,
        vat_rate,
        vat_handling,
      },
    },
    deleteCartItem,
  } = useCart();
  const unloading_cost = order_rows?.find(
    order_row => order_row.type === 'unloading_cost'
  );

  const packaging_cost = order_rows?.find(
    order_row => order_row.type === 'liune_packaging_cost'
  );
  return (
    <>
      <div className={`${visible && styles.visible} ${styles.cartDropdown}`}>
        <div>
          <div className={`${styles.cartDropdownHeader}`}>
            <h4>{t<string>('header.cart_dropdown.title')}</h4>
          </div>

          {loading && <CartDropdownLoaderList count={2} />}

          {itemsCount <= 0 && (
            <div className={`${styles.emptyCart}`}>
              <h4>{t<string>('header.cart_dropdown.empty_title')}</h4>
              <p>0 {t<string>('header.cart_dropdown.empty_text')}</p>
            </div>
          )}
          <div>
            {!loading &&
              order_rows
                ?.filter(
                  order_row =>
                    !order_row.order_row_id && order_row.type === 'product'
                )
                .map((order_row, index) => {
                  return (
                    <CartDropdownItem
                      deleteCartItem={deleteCartItem}
                      item={order_row}
                      key={index}
                    />
                  );
                })}
          </div>
          {!loading && order_rows?.length > 0 && (
            <div className={`${styles.cartDropdownFooter}`}>
              <div className={`${styles.footerRow} ${styles.shipment}`}>
                <p>{t<string>('header.cart_dropdown.shipping_cost')}</p>
                <p className={`${styles.shipmentPrice}`}>
                  {toThousand(delivery_method_price_vat_excluded ?? 0)} €
                </p>
              </div>
              {unloading_cost ? (
                <div className={`${styles.footerRow}`}>
                  <p>{unloading_cost.product[`product_name_${locale}`]}</p>
                  <p className={`${styles.shipmentPrice}`}>
                    {toThousand(
                      unloading_cost.discount_unit_price_vat_excluded ?? 0
                    )}{' '}
                    €
                  </p>
                </div>
              ) : null}

              {packaging_cost ? (
                <div className={`${styles.footerRow}`}>
                  <p>{t<string>('header.cart_dropdown.packaging_cost')}</p>
                  <p className={`${styles.shipmentPrice}`}>
                    {toThousand(
                      packaging_cost.discount_unit_price_vat_excluded ?? 0
                    )}{' '}
                    €
                  </p>
                </div>
              ) : null}

              <div className={`${styles.footerRow} ${styles.vat} `}>
                {vat_rate === 0 && vat_handling.visible_text && (
                  <p>{t<string>(getVATTextKey(vat_handling.visible_text))}</p>
                )}
                {(vat_rate > 0 || !vat_handling.visible_text) && (
                  <>
                    <p>
                      {t<string>('header.cart_dropdown.vat')}{' '}
                      {vat_rate && `(${vat_rate}%)`}
                    </p>
                    <p className={`${styles.shipmentPrice}`}>
                      {toThousand(
                        total_price_vat_included - total_price_vat_excluded ?? 0
                      )}{' '}
                      €
                    </p>
                  </>
                )}
              </div>
              <div className={`${styles.footerRow}`}>
                <p className={`${styles.totalPriceLabel}`}>
                  {t<string>('header.cart_dropdown.total_price')}
                </p>
                <p>{toThousand(total_price_vat_included)} €</p>
              </div>
              <Button block={true} href={CHECKOUT_URL[locale]} variant={'link'}>
                {t<string>('header.cart_dropdown.checkout_button')}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
