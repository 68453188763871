import NavLink from '@components/Common/NavLink';

import {
  faTimes,
  faAngleDown,
  faAngleUp,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import Cookie from 'js-cookie';
import styles from './HamburgerMenu.module.css';
import Selection from './Selection';
import Link from '@components/Common/Link';
import SelectionMenu from './SelectionMenu';
import {AccountDropDownLinks, HeaderNavigation} from '@constants/navigations';
import {LocaleEnum} from '@enums/locale';
import {useRouter} from 'next/router';
import {useTranslation} from 'next-i18next';
import {useModal} from '@contexts/index';
import {ModalActionTypes} from '@contexts/actions';
import {useSession} from 'next-auth/react';
import {languages} from '@constants/../../constants/common';
import {signOut} from 'next-auth/react';

interface HamburgerMenuProps {
  toggle: () => void;
  isOpen: boolean;
}
export default function HamburgerMenu({toggle, isOpen}: HamburgerMenuProps) {
  const router = useRouter();
  const locale = router.locale as LocaleEnum;
  const links = HeaderNavigation[locale];
  const accountLinks = AccountDropDownLinks[locale];
  const {t} = useTranslation('common');
  const {status} = useSession();
  const {dispatch} = useModal();
  const showLoginModal = () =>
    dispatch({type: ModalActionTypes.TOGGLE_LOGIN_MODAL});

  const switchLocale = (locale: LocaleEnum) => {
    Cookie.set('NEXT_LOCALE', locale);
  };

  return (
    <>
      <div className="hamburgerMenuOverlay"> </div>
      <div
        className={`${styles.hamburgerMenu} ${
          isOpen ? styles.activeHamburgerMenu : ''
        }`}
      >
        <div className={`${styles.hamburgerClose}`} onClick={toggle}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
        <div className={`${styles.hamburgerMenuContent}`}>
          <ul className={`${styles.hamburgerMenuNav}`}>
            <Selection
              activeClass={styles.menuItemActive}
              classNames={`${styles.menuItem}`}
            >
              <Link onClick={event => event.preventDefault()}>
                {languages.find(lang => lang.locale === locale)?.title}
                <FontAwesomeIcon icon={faAngleUp} />
                <FontAwesomeIcon id={'faAngleDown'} icon={faAngleDown} />
              </Link>
              <SelectionMenu key={1} classNames={`${styles.MenuSelection}`}>
                {languages.map((lang, index) => (
                  <NavLink
                    href={'/'}
                    onClick={() => switchLocale(lang.locale as LocaleEnum)}
                    locale={lang.locale}
                    key={index}
                  >
                    {lang.title}
                  </NavLink>
                ))}
              </SelectionMenu>
            </Selection>
            <Selection
              activeClass={styles.menuItemActive}
              classNames={`${styles.menuItem}`}
            >
              <Link onClick={event => event.preventDefault()}>
                {t<string>('top_nav.my_account')}
                <FontAwesomeIcon icon={faAngleUp} />
                <FontAwesomeIcon id={'faAngleDown'} icon={faAngleDown} />
              </Link>
              <SelectionMenu key={1} classNames={`${styles.MenuSelection}`}>
                {accountLinks.map((link, index) => (
                  <NavLink href={link.link} locale={locale} key={index}>
                    {link.name}
                  </NavLink>
                ))}
                <NavLink
                  onClick={() => signOut({redirect: true, callbackUrl: '/'})}
                >
                  {t<string>('top_nav.sign_out')}
                </NavLink>
              </SelectionMenu>
            </Selection>
            {links.map((link, index) => (
              <NavLink
                key={index}
                href={link.link}
                locale={locale}
                classNames={`${styles.menuItem}`}
              >
                {link.name}
              </NavLink>
            ))}
            {status === 'authenticated' ? (
              <></>
            ) : (
              <NavLink
                classNames={`${styles.menuItem}`}
                onClick={() => showLoginModal()}
              >
                {t<string>('top_nav.sign_in')}
              </NavLink>
            )}
          </ul>
        </div>
      </div>
    </>
  );
}
