import {LegacyRef, MouseEventHandler} from 'react';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface NavItemProps extends ComponentWithChildrenProps {
  classNames?: string;
  onClick?: MouseEventHandler<unknown>;
  elementRef?: LegacyRef<HTMLLIElement>;
  locale?: string;
}
export default function NavItem({
  children,
  classNames = '',
  onClick,
  elementRef,
  ...rest
}: NavItemProps) {
  return (
    <li
      ref={elementRef}
      onClick={onClick}
      className={`d-inline-block ${classNames}`}
      {...rest}
    >
      {children}
    </li>
  );
}
