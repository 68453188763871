import TextField from '@components/Forms/TextField';
import {faLock} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import FormGroup from '@components/Forms/FormGroup';

import Button from '@components/Forms/Button';

import {useModal} from '@contexts/index';
import {ModalActionTypes as Actions} from '@contexts/actions';

import {FormEvent, useState} from 'react';
import {useRequest} from '@hooks/use-request';
import {ToastContainer, toast} from 'react-toastify';
import {useRouter} from 'next/router';
import {ForgotPasswordLinks} from '@constants/navigations';
import {LocaleEnum} from '@enums/locale';
import {Modal, ModalHeader, ModalBody} from 'reactstrap';

import styles from './style.module.css';

import {useTranslation} from 'next-i18next';

export default function ForgotPasswordModal() {
  const {t} = useTranslation();
  const {state, dispatch} = useModal();
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  const locale = router.locale as unknown as LocaleEnum;

  const {isForgotPasswordModalOpen} = state;
  const {errors, sendRequest} = useRequest(locale);

  const ForgotPasswordCallback = ForgotPasswordLinks[locale];
  const callback_url = process.env.NEXT_PUBLIC_APP_URL + ForgotPasswordCallback;

  const toggleModal = () => {
    dispatch({type: Actions.TOGGLE_FORGOT_PASSWORD_MODAL});
  };
  const handleForgotPasswordForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    await sendRequest({
      url: 'password/forgot',
      method: 'post',
      body: {email, callback_url},
      onSuccess: () => {
        setEmail('');
        toast.success(
          t<string>('password_recovery.account_recovery_email_sent', {
            ns: 'common',
          }),
          {
            autoClose: 1000,
          }
        );
        toggleModal();
      },
    });
    setLoading(false);
  };

  return (
    <Modal
      key={111}
      contentClasses={styles.loginModalContent}
      isOpen={isForgotPasswordModalOpen}
      center={true}
    >
      <ModalHeader toggle={toggleModal} classNames={styles.modalHeader}>
        <div className={styles.loginHeader}>
          <div className={styles.modalHeaderIcon}>
            <FontAwesomeIcon icon={faLock} />
          </div>
          <div className={styles.modelHeaderTitle}>
            <h4>
              {t<string>('password_recovery.password_recovery_title', {
                ns: 'common',
              })}
            </h4>
            <p>
              {t<string>('password_recovery.restore_access_title', {
                ns: 'common',
              })}
            </p>
          </div>
        </div>
      </ModalHeader>
      <ModalBody classNames={styles.loginModalBody}>
        <div className="">
          <form onSubmit={handleForgotPasswordForm}>
            <FormGroup>
              <label htmlFor="">
                {t<string>('password_recovery.recovery_link_recipient', {
                  ns: 'common',
                })}
              </label>
              <TextField
                onChange={e => setEmail(e.target.value)}
                required
                value={email}
                placeholder={t<string>('password_recovery.your_email_address', {
                  ns: 'common',
                })}
                type={'email'}
              />
              {errors.length > 0 && (
                <p
                  style={{fontSize: '14px'}}
                  className={'mb-0 text text-danger'}
                >
                  {
                    errors.find(
                      err =>
                        err.field === 'email' ||
                        err.field === 'callback_url' ||
                        err.field === undefined
                    )?.message
                  }
                </p>
              )}
            </FormGroup>
            <FormGroup classNames={'mt-3 mb-3'}>
              <Button disabled={loading || !email} type={'submit'} block={true}>
                {t<string>('password_recovery.reset_password', {
                  ns: 'common',
                })}
              </Button>
            </FormGroup>
          </form>
        </div>
        <ToastContainer />
      </ModalBody>
    </Modal>
  );
}
