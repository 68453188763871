import NavItem from '../NavItem';
import Link from '../Link';
import {MouseEventHandler, ReactNode} from 'react';

interface NavLinkProps {
  target?: '_blank';
  classNames?: string;
  href?: string | undefined;
  as?: URL;
  locale?: string;
  onClick?: MouseEventHandler<unknown>;
  variant?: 'nextLink' | 'buttonLink' | 'link';
  children?: ReactNode;
}
export default function NavLink(props: NavLinkProps) {
  const {
    children,
    classNames = '',
    href,
    as,
    locale,
    onClick,
    variant,
    ...rest
  } = props;
  return (
    <NavItem classNames={`${classNames}`}>
      <Link
        onClick={event => {
          if (onClick) onClick(event);
        }}
        variant={variant}
        href={href}
        as={as}
        locale={locale}
        {...rest}
      >
        {children}
      </Link>
    </NavItem>
  );
}
