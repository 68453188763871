import {Fragment, cloneElement} from 'react';
import {useModal} from '@contexts/index';
import {ModalActionTypes} from '@contexts/actions';
import {ComponentWithChildrenProps} from '@interfaces/component';

type LoginButtonProps = ComponentWithChildrenProps;
export default function LoginButton(props: LoginButtonProps) {
  const {children, ...otherProps} = props;
  const {dispatch} = useModal();
  const onClick = () => dispatch({type: ModalActionTypes.TOGGLE_LOGIN_MODAL});
  return (
    <Fragment>
      {(cloneElement(<></>, {onClick, ...otherProps}), children)}
    </Fragment>
  );
}
