import ContentLoader from 'react-content-loader';
import styles from './style.module.css';

export function CartDropdownLoader(props: {count?: number}) {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={70}
      viewBox="0 0 300 70"
      backgroundColor="#f5f5f5"
      foregroundColor="#ebebeb"
      {...props}
    >
      {/*Image box*/}
      <rect x={10} y={0} rx={0} ry={0} width={100} height={60} />

      {/*/!*Title*!/*/}
      <rect x={120} y={5} rx={0} ry={0} width={180} height={10} />

      <rect x={120} y={25} rx={0} ry={0} width={100} height={10} />

      <rect x={250} y={25} rx={0} ry={0} width={100} height={10} />

      <rect x={120} y={45} rx={3} ry={3} width={200} height={10} />
    </ContentLoader>
  );
}

export default function CartDropdownLoaderList(props: {count?: number}) {
  const {count = 1} = props;
  const loaderList = [];
  for (let i = 0; i < count; i++) {
    loaderList.push(
      <div key={i} className={`${styles.cartDropdownLoader}`}>
        <CartDropdownLoader />
      </div>
    );
  }
  return <>{loaderList}</>;
}
