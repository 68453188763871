import {useRef} from 'react';
import {ComponentWithChildrenProps} from '@interfaces/component';

interface SelectionMenuProps extends ComponentWithChildrenProps {
  classNames?: string;
}
export default function SelectionMenu({
  children,
  classNames = '',
}: SelectionMenuProps) {
  const ref = useRef(null);

  return (
    <ul ref={ref} key={102222} className={`${classNames}`}>
      {children}
    </ul>
  );
}
