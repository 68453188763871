import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

import TextField from '@components/Forms/TextField';
import NavLink from '@components/Common/NavLink';
import {useTranslation} from 'next-i18next';
import {AskOfferLinks} from '@constants/navigations';
import {useRouter} from 'next/router';
import {LocaleEnum} from '@enums/locale';
import styles from './style.module.css';
import Divider from '@components/Divder';
import {HASH_TAGS} from '@constants/common';

export default function Footer() {
  const {t} = useTranslation('common');
  const {locale} = useRouter();
  return (
    <footer className={styles.footer}>
      <div className="container">
        <div className="row">
          <div className="col-md-12 text-center">
            <div className={`${styles.footerSection}`}>
              <div className={`${styles.subscribe}`}>
                <h3>{t<string>('footer.title')}</h3>
                <form action="https://liune.us14.list-manage.com/subscribe">
                  <div className="row justify-content-center my-4">
                    <div className="col-8 col-md-4 col-lg-3">
                      <input
                        type="hidden"
                        name="u"
                        value={'f1762c77cf875c185505004ac'}
                      />
                      <input type="hidden" name="id" value={'63fff9f67c'} />
                      <TextField
                        type={'email'}
                        name={'MERGE0'}
                        id={'MERGE0'}
                        required={true}
                        classNames={styles.subscribeInput}
                        placeholder={t<string>('footer.input_placeholder')}
                      />
                    </div>
                    <div className="col-3 col-md-2 col-lg-1 p-0">
                      <button
                        type={'submit'}
                        className={`${styles.subscribeBtn} btn btn-primary`}
                      >
                        {t<string>('footer.subscribe_button')}
                      </button>
                    </div>
                  </div>
                </form>
                <p className="mb-0">{t<string>('footer.footer_text')}</p>
              </div>
            </div>
          </div>

          <hr className="my-5" />

          <div className="col-sm-12 col-md-12">
            <div className={styles.footerSection}>
              <ul className={styles.footerMenu + ' ' + styles.topLinks}>
                <NavLink href={t<string>('footer.company.media_link')}>
                  {t<string>('footer.company.media')}
                </NavLink>
                <Divider />
                <NavLink href={t<string>('footer.company.company_link')}>
                  {t<string>('footer.company.company')}
                </NavLink>
                <Divider />
                <NavLink href={t<string>('footer.company.reference_link')}>
                  {t<string>('footer.company.reference')}
                </NavLink>
                <Divider />
                <NavLink>{t<string>('footer.company.prices')}</NavLink>
                <Divider />
                <NavLink href={t<string>('footer.company.personnel_link')}>
                  {t<string>('footer.company.personnel')}
                </NavLink>
              </ul>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 d-flex
                    justify-content-sm-end justify-content-md-start"
          >
            <div className={styles.footerSection}>
              <ul className={styles.footerMenu}>
                <NavLink
                  target={'_blank'}
                  href={
                    AskOfferLinks[`link_${locale as unknown as LocaleEnum}`]
                  }
                >
                  {t<string>('footer.quick_links.feedback')}
                </NavLink>
                <Divider />
                <NavLink
                  href={t<string>(
                    'footer.quick_links.career_opportunities_link'
                  )}
                >
                  {t<string>('footer.quick_links.career_opportunities')}
                </NavLink>
                <Divider />
                <NavLink
                  href={t<string>(
                    'footer.quick_links.research_and_development_link'
                  )}
                >
                  {t<string>('footer.quick_links.research_and_development')}
                </NavLink>
                <Divider />
                <NavLink
                  href={t<string>('footer.quick_links.terms_of_service_link')}
                >
                  {t<string>('footer.quick_links.terms_of_service')}
                </NavLink>
                <Divider />
                <NavLink
                  href={t<string>('footer.quick_links.privacy_statement_link')}
                >
                  {t<string>('footer.quick_links.privacy_statement')}
                </NavLink>
              </ul>
            </div>
          </div>
          <div className="col-md-12 pt-2">
            <ul className={styles.socialMedia}>
              <NavLink
                target={'_blank'}
                href={'https://www.facebook.com/liunefinland/'}
              >
                <FontAwesomeIcon size="2x" icon={faFacebookF} />
              </NavLink>
              <NavLink
                target={'_blank'}
                href={'https://www.linkedin.com/company/liune'}
              >
                <FontAwesomeIcon size="2x" icon={faLinkedinIn} />
              </NavLink>
              <NavLink
                target={'_blank'}
                href={'https://www.instagram.com/liune.fi/'}
              >
                <FontAwesomeIcon size="2x" icon={faInstagram} />
              </NavLink>
              <NavLink
                target={'_blank'}
                href={
                  'https://www.youtube.com/channel/UCwEPAI04Q5ct-UpyneAKZSg/featured'
                }
              >
                <FontAwesomeIcon size="2x" icon={faYoutube} />
              </NavLink>
            </ul>
          </div>
          <div className="col-md-12 pt-1 text-center">
            <ul className={styles.socialMedia}>
              {HASH_TAGS.map(tag => (
                <NavLink
                  classNames={styles['hash-tags']}
                  key={tag}
                  target={'_blank'}
                  href={'https://www.instagram.com/liune.fi/'}
                >
                  #{tag}
                </NavLink>
              ))}
            </ul>
            <p className={styles.copyright}>
              © 2016-2021 Aulis Lundell Oy. All rights reserved. HD SofT
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}
